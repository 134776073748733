import * as _shams2 from "has-symbols/shams";

var _shams = "default" in _shams2 ? _shams2.default : _shams2;

var exports = {};
var hasSymbols = _shams;

exports = function hasToStringTagShams() {
  return hasSymbols() && !!Symbol.toStringTag;
};

export default exports;